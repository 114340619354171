(function() {
	const loginErrorEl = document.querySelector('#login-container .error');
	const loginEmailEl = document.getElementById('login-email');
	const loginPasswordEl = document.getElementById('login-password');
	const loginButtonEl = document.querySelector('#login-container input[type="submit"]');
	const signupErrorEl = document.querySelector('#signup-container .error');
	const signupEmailEl = document.getElementById('signup-email');
	const signupPasswordEl = document.getElementById('signup-password');
	const signupOrganizationEl = document.getElementById('signup-organization');
	const signupButtonEl = document.querySelector('#signup-container input[type="submit"]');

	document.querySelector('.overlay .container').addEventListener('click', function(e) {
		e.stopPropagation();
	});

	document.querySelector('.overlay').addEventListener('click', function(e) {
		document.body.classList.remove('u-no-overflow');
		document.querySelector('.overlay').style.display = 'none';
	});

	document.getElementById('button-show-login').addEventListener('click', function(e) {
		e.preventDefault();
		document.body.classList.add('u-no-overflow');
		document.getElementById('login-container').style.display = 'block';
		document.getElementById('signup-container').style.display = 'none';
		document.querySelector('.overlay').style.display = 'block';
		loginEmailEl.focus();
	});

	document.querySelector('#login-container form').addEventListener('submit', function(e) {
		e.preventDefault();
		loginButtonEl.click();
	});

	loginButtonEl.addEventListener('click', function loginListener(e) {
		e.preventDefault();

		login(loginButtonEl, loginListener);
	});

	document.querySelectorAll('.button-show-signup').forEach(function(el) {
		el.addEventListener('click', function(e) {
			e.preventDefault();
			document.body.classList.add('u-no-overflow');
			document.getElementById('login-container').style.display = 'none';
			document.getElementById('signup-container').style.display = 'block';
			document.querySelector('.overlay').style.display = 'block';
			signupEmailEl.focus();
		});
	});

	document.querySelector('#signup-container form').addEventListener('submit', function(e) {
		e.preventDefault();
		signupButtonEl.click();
	});

	signupButtonEl.addEventListener('click', function signupListener(e) {
		e.preventDefault();

		qanda.shared.blockedRequest(signupButtonEl, signupListener, 'POST', 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/users', {
			body: {
				email: signupEmailEl.value,
				password: signupPasswordEl.value,
				organization: signupOrganizationEl.value,
			},
			onSuccess: function() {
				signupErrorEl.textContent = '';
			},
			onFail: function(error) {
				signupErrorEl.textContent = error;
			}
		}, function(httpRequest) {
			if (httpRequest.status === 200) {
				loginEmailEl.value = signupEmailEl.value;
				loginPasswordEl.value = signupPasswordEl.value;
				login(signupButtonEl, signupListener);
			} else {
				console.log('There was a problem with the request.', httpRequest.responseText);
			}
		});
	});

	function login(el, listener) {
		qanda.shared.blockedRequest(el, listener, 'POST', 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/users/login', {
			body: {
				email: loginEmailEl.value,
				password: loginPasswordEl.value,
			},
			onSuccess: function() {
				loginErrorEl.textContent = '';
			},
			onFail: function(error) {
				loginErrorEl.textContent = error;
			}
		}, function(httpRequest) {
			if (httpRequest.status === 200) {
				var response = JSON.parse(httpRequest.responseText);
				window.localStorage.setItem('token', response.token.id);
				window.location.href = '/app';
			} else {
				console.log('There was a problem with the request.', httpRequest.responseText);
			}
		});
	}
})();